import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"

import { media } from "../../styles/media"

import LangLayout from "../../components/LangLayout"
import PageHeaderMedia from "../../components/PageHeaderMedia"
import SEO from "../../components/SEO"
import { useLocationData } from "../../hooks/locationHook"

const StyledContents = styled.div`
  width: 90%;
  max-width: 900px;
  margin: 50px auto;
  .conSec {
    padding: 1.62em 0 3.62em;
    h2 {
      color: #3a3a3a;
      font-size: 1.62em;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.62em;
      &:before {
        display: block;
        content: "";
        height: 1.32em;
        width: 8px;
        margin-right: 0.32em;
        border-radius: 1px;
        background: ${({ theme }) => theme.colors.primary.red};
      }
    }
    h3 {
      padding: 0.62em 0;
      font-weight: bold;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      &:after {
        display: block;
        content: "";
        height: 2px;
        border-radius: 2px;
        width: 38%;
        background: ${({ theme }) => theme.colors.primary.green};
        margin: 1.62em 0;
      }
    }
    p {
      width: 100%;
      text-align: center;
    }
  }
`

const StyledPhilo = styled.div`
  ul {
    font-size: 1.12em;
    list-style-type: circle;
    padding-left: 1.62em;
    padding-top: 1.62em;
    li {
      margin-bottom: 1.62em;
    }
  }
`

const StyledCeo = styled.div`
  padding-top: 1.62em;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  .ceoPhoto {
    width: 38%;
  }
  .ceoMessage {
    width: 60%;
    p {
      text-align: left;
      padding: 0em 0 1.62em;
      &.names {
        text-align: right;
      }
    }
  }
  ${() =>
    media.sp(css`
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      .ceoPhoto {
        width: 40%;
        margin-bottom: 1.62em;
      }
      .ceoMessage {
        width: 100%;
      }
    `)}
`

const PhiloPage = ({ location }) => {
  const data = useStaticQuery(query)
  useLocationData(location.pathname)

  return (
    <LangLayout>
      <SEO>
        <title>Francois' Aim｜Francois</title>
        <meta
          name="description"
          content="Up to now, and from now.For Health and Taste."
        />
      </SEO>
      <PageHeaderMedia
        fluid={data.zoo.langTopMedia.headerMedia.childImageSharp.fluid}
        pageTitles={data.zoo.langTopMedia.headerTitleEn}
        pageText={data.zoo.langTopMedia.subTitleEn}
      />
      <StyledContents>
        <div className="conSec">
          <h2>{data.zoo.enPhiloSection.aimTitle}</h2>
          <h3>{data.zoo.enPhiloSection.aimBig}</h3>
          <p>{data.zoo.enPhiloSection.aimSmall}</p>
        </div>
        <div className="conSec">
          <h2>{data.zoo.enPhiloSection.corpPhiloTitle}</h2>
          <StyledPhilo>
            <div
              dangerouslySetInnerHTML={{
                __html: data.zoo.enPhiloSection.corpPhilo,
              }}
            />
          </StyledPhilo>
        </div>
        <div className="conSec">
          <h2>{data.zoo.enPhiloSection.manaPhiloTitle}</h2>
          <StyledPhilo>
            <div
              dangerouslySetInnerHTML={{
                __html: data.zoo.enPhiloSection.manePhilo,
              }}
            />
          </StyledPhilo>
        </div>
        <div className="conSec">
          <h2>{data.zoo.enPhiloSection.ceoTitle}</h2>
          <StyledCeo>
            <div className="ceoPhoto">
              <Image
                fluid={data.zoo.enPhiloSection.ceoPhoto.childImageSharp.fluid}
              />
            </div>
            <div className="ceoMessage">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.zoo.enPhiloSection.ceoMessage,
                }}
              />
              <p className="names">{data.zoo.enPhiloSection.ceoName}</p>
            </div>
          </StyledCeo>
        </div>
      </StyledContents>
    </LangLayout>
  )
}

export const query = graphql`
  {
    zoo: strapiLangPhilo {
      langTopMedia {
        headerTitleEn
        subTitleEn
        headerMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      enPhiloSection {
        aimTitle
        aimBig
        aimSmall
        corpPhiloTitle
        corpPhilo
        manaPhiloTitle
        manePhilo
        ceoTitle
        ceoMessage
        ceoName
        ceoPhoto {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default PhiloPage
